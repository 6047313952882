// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-404-page-tsx": () => import("./../../../src/pages_/404/404.page.tsx" /* webpackChunkName: "component---src-pages-404-404-page-tsx" */),
  "component---src-pages-410-410-page-tsx": () => import("./../../../src/pages_/410/410.page.tsx" /* webpackChunkName: "component---src-pages-410-410-page-tsx" */),
  "component---src-pages-home-page-home-page-tsx": () => import("./../../../src/pages_/home-page/home.page.tsx" /* webpackChunkName: "component---src-pages-home-page-home-page-tsx" */),
  "component---src-pages-reserve-page-reserve-page-tsx": () => import("./../../../src/pages_/reserve-page/reserve.page.tsx" /* webpackChunkName: "component---src-pages-reserve-page-reserve-page-tsx" */)
}

