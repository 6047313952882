/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import React from 'react';
import { RecoilRoot } from 'recoil';

import LangSelect from './src/hoc/LangSelect';
import ErrorBoundary from './src/hoc/ErrorBoundary';
import ErrorPage from './src/pages_/errorPage/error.page';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const wrapPageElement = ({ element, props }) => {
  return (
    <RecoilRoot>
      <ErrorBoundary ErrorComponent={ErrorPage}>
        <React.Suspense fallback={<div>Loading...</div>}>
          <LangSelect lang={props.pageContext.lang}>{element}</LangSelect>
        </React.Suspense>
      </ErrorBoundary>
    </RecoilRoot>
  );
};

export { wrapRootElement } from './gatsby-ssr';
