/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable react/jsx-key */

/**
 * Implement Gatsby's SSR (Server Side Rendering) APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/ssr-apis/
 */

import React from 'react';
import { RecoilRoot } from 'recoil';
import ErrorBoundary from './src/hoc/ErrorBoundary';
import ErrorPage from './src/pages_/errorPage/error.page';

export const onRenderBody = ({ pathname }) => {
  /**
   * IMPORTANT NOTE for developers:
   *    This will NOT render correct canonical URLs in development mode.
   *    You need to build static pages inside the public folder (e.g. yarn build),
   *    and serve them, in order to test canonical urls.
   */

  let myPathName = pathname;

  // For some reason, the createPage action from gatsby-node, sends '/lang/undefined' for '' path,
  // ... even though if you console.log `path` in createPage, it logs it normally as an empty string.
  if (myPathName.includes('undefined')) {
    myPathName = myPathName.replace('undefined', '');
  }
};

export const wrapRootElement = ({ element }) => {
  return element;
};

export const wrapPageElement = ({ element, props }) => {
  return (
    <RecoilRoot>
      <ErrorBoundary ErrorComponent={ErrorPage}>{element}</ErrorBoundary>
    </RecoilRoot>
  );
};
